import { createAllInOne, getCalculatedPrice, updateAllInOne } from './mpApi';

export const getMigrationStatusColor = (status) => {
  // new, in_progress, stopped, error, paused, finished
  let color = '';
  switch (status) {
    case 'new':
      color = 'blue';
      break;
    case 'error':
      color = 'red';
      break;
    case 'stopped':
      color = 'red';
      break;
    case 'paused':
      color = 'teal';
      break;

    default:
      color = 'green';
      break;
  }
  return color;
};
export const getAllInOneMigrationStatusColor = (status) => {
  // new, in_progress, stopped, error, paused, finished
  let color = '';
  switch (status) {
    case 'new':
      color = 'blue';
      break;
    case 'in_progress':
      color = 'teal';
      break;

    default:
      color = 'green';
      break;
  }
  return color;
};

export const getMigrationLinkByStatus = (
  status,
  migrationId,
  isDemo,
  referal = 'migration'
) => {
  // new, in_progress, stopped, error, paused, finished
  let link = '';
  switch (status) {
    case 'init':
      link = `/migrations/${migrationId}/connect/`;
      break;
    case 'new':
      link = `/migrations/${migrationId}/config/`;
      break;
    case 'in_progress':
    case 'stopped':
    case 'error':
    case 'paused':
      link = `/migrations/${migrationId}/${referal}/process/`;
      break;
    case 'finished':
      link = `/migrations/${migrationId}/${referal}/report/`;
      break;
    default:
      link = `/migrations/${migrationId}/${referal}/process/`;
      break;
  }
  return link;
};

export const getAllInOneMigrationLinkByStatus = (
  status,
  migrationId,
  showCompleteSetUpButton = false
) => {
  // new, in_progress, stopped, error, paused, finished
  let link = '';
  if (showCompleteSetUpButton) {
    link = `/migrations/all-in-one-migration/${migrationId}/connect/`;
    return link;
  }
  switch (status) {
    case 'new':
      link = `/migrations/all-in-one-migration/${migrationId}/checkout/`;
      break;
    default:
      link = `/migrations/all-in-one-migration/${migrationId}/details/`;
      break;
  }
  return link;
};

export const detectMappingStructure = (targetObj) => {
  let oneToOne = true;
  let firstValue = targetObj[0];

  for (let key in firstValue) {
    if (typeof firstValue[key] === 'object') {
      oneToOne = false;
    }
  }

  return oneToOne;
};

export const validateSignature = function (query, app_secret) {
  const crypto = require('crypto');
  var parameters = [];
  for (var key in query) {
    if (key != 'hmac') {
      parameters.push(key + '=' + query[key]);
    }
  }
  var message = parameters.sort().join('&');

  var digest = crypto
    .createHmac('sha256', `${app_secret}`)
    .update(message)
    .digest('hex');

  return digest && query?.hmac
    ? crypto.timingSafeEqual(Buffer.from(digest), Buffer.from(query.hmac))
    : false;
};

export const generateMappingForFormik = function (mappings) {
  /**
   * Generate mappings for Formik
   */
  const results = {};
  Object.keys(mappings).map((key) => {
    const sources = mappings[key].source;
    if (key !== 'shops' && key !== 'languages') {
      const target = mappings[key].target;
      sources.map((source) => {
        let pairTargetObj = {};
        target.forEach((item) => {
          let key = Object.keys(item)[0];
          pairTargetObj = { ...pairTargetObj, [key]: item[key][0].id };
        });
        if (source.paired_target_id === null) {
          if (!detectMappingStructure(target)) {
            source.paired_target_id = {
              ...pairTargetObj,
            };
          } else {
            source.paired_target_id = target[0].id;
          }
        }
      });
    }

    const result = sources.map((source) => {
      const res = { [source.id]: { ...source } };
      return res;
    });
    if (key === 'shops' || key === 'languages') {
      let mappingData;
      mappings[key].source.forEach((item) => {
        //if paired_target_id is not null, then set paired and selected
        if (item.paired_target_id) {
          mappingData = {
            ...mappingData,
            [item.id]: item,
            paired: item.paired_target_id,
            selected: item.id,
          };
        } else {
          mappingData = { ...mappingData, [item.id]: item };
        }
      });
      if (
        !mappingData?.selected &&
        mappings[key].source.length > 0 &&
        mappings[key].target.length > 0
      ) {
        //define default values for selected and paired
        mappingData = {
          ...mappingData,
          selected: mappings[key].source[0]?.id,
          paired: mappings[key].target[0]?.id,
        };
      }

      results[key] = { ...mappingData };
    } else {
      results[key] = Object.assign({}, ...result);
    }
  });
  return results;
};

export const cleanURL = function (url) {
  // remove disallowed strings and clear string to the end after disallowed strings
  const disallowedStrings = ['wp-content', 'wp-admin', 'admin'];
  disallowedStrings.forEach((str) => {
    if (url.includes(str)) {
      const index = url.indexOf(str);
      url = url.slice(0, index);
    }
  });

  // remove query string from the URL
  if (url.includes('?')) {
    const index = url.indexOf('?');
    url = url.slice(0, index);
  }

  url = url.trim();

  return url;
};

export const findTheLargestTime = (timeArr) => {
  let maxTime = timeArr[0].remaining_seconds_to_upload;
  timeArr.forEach((item) => {
    if (item.remaining_seconds_to_upload > maxTime) {
      maxTime = item.remaining_seconds_to_upload;
    }
  });

  return maxTime;
};

export const checkCookie = (cookieName) => {
  // Get the value of the document.cookie property
  let cookies = document.cookie;

  // Split the string into an array of individual cookies
  let cookieArray = cookies.split(';');

  // Loop through each cookie to check if it contains the specified name
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim(); // Remove leading and trailing whitespaces
    if (cookie.indexOf(cookieName + '=') === 0) {
      // The cookie with the specified name exists
      return true;
    }
  }

  // The cookie with the specified name was not found
  return false;
};

export const generateEntitesString = (entities) => {
  let selectedEntityNames = [];
  for (const entity in entities) {
    const ent = entities[entity];
    if (ent.enabled) {
      selectedEntityNames = [...selectedEntityNames, ent.entity.name];
    }
  }
  return selectedEntityNames.length !== 0
    ? selectedEntityNames.length === 1
      ? `${selectedEntityNames[0]}`
      : `These entities (${selectedEntityNames.join(', ').toLowerCase()})`
    : false;
};

export const embeddedCheck = () => {
  return window.self !== window.top;
};

export const checkSmartUpdateOptions = (config) => {
  return config.some(
    (option) => option.entity.smart_update_options.length !== 0
  );
};

export const checkTeamMemmber = (teamMemberEmails, useEmail) => {
  return teamMemberEmails.find((memberEmail) => memberEmail === useEmail);
};

export const generateInitialEntitiesAllInOne = (
  formikEntities,
  indexPage = false,
  initialEntities = {}
) => {
  let entitiesFormikInitialValues = {};
  // sort entities by order
  const entities = formikEntities.sort(function (a, b) {
    return a.order - b.order;
  });
  {
    entities.map((key) => {
      if (
        (key.entity.alias === 'products' ||
          key.entity.alias === 'customers' ||
          key.entity.alias === 'orders') &&
        indexPage
      ) {
        entitiesFormikInitialValues[key.entity.alias] = {
          ...key,
          enabled: true,
          total: key.total || initialEntities[key.entity.alias],
        };
      } else {
        entitiesFormikInitialValues[key.entity.alias] = key;
      }
    });
  }
  return entitiesFormikInitialValues;
};

export const generateInitialAdditionalsAllInOne = (formikAdditionals) => {
  let additionalsFormikInitialValues = {};
  // sort additionals by order
  const additionals = formikAdditionals.sort(function (a, b) {
    return a.order - b.order;
  });
  {
    additionals.map((key) => {
      additionalsFormikInitialValues[key.alias] = {
        ...key,
        total: key?.data?.total ? key.data.total : 0,
      };
    });
  }
  return additionalsFormikInitialValues;
};

export const onHandleAllInOneFormSubmit = async (
  values,
  fetcher,
  router,
  requestType = 'create',
  migrationId = null,
  currentLocaleForRouter = ''
) => {
  let entities = [];
  let additionalOptions = [];
  let source_cart = values.sourceCartSelect?.alias;
  let target_cart = values.targetCartSelect?.alias;
  let note = values.additionalNote;
  let customFee = Number(values.customFee) ? Number(values.customFee) : 0;

  for (let key in values.entities) {
    if (values.entities[key].enabled && values.entities[key]?.total) {
      entities.push({
        entity: {
          alias: key,
        },
        total: values.entities[key]?.total,
        enabled: true,
      });
    } else {
      entities.push({
        entity: {
          alias: key,
        },
        enabled: values.entities[key].enabled,
      });
    }
  }
  for (let key in values.additionals) {
    if (
      (values.additionals[key]?.enabled &&
        values.additionals[key]?.data?.total) ||
      values.additionals[key]?.total
    ) {
      additionalOptions.push({
        id: values.additionals[key].id,
        data: {
          total: Number(
            values.additionals[key]?.data?.total
              ? values.additionals[key]?.data?.total
              : values.additionals[key]?.total
          ),
          enabled: true,
        },
      });
    } else {
      additionalOptions.push({
        id: values.additionals[key].id,
        data: {
          enabled: values.additionals[key]?.enabled ? true : false,
        },
      });
    }
  }
  const body = {
    source_cart,
    target_cart,
    entities,
    additional_options: additionalOptions,
    additionals: [],
    note,
    customization_fee: customFee,
  };
  let resp = null;
  if (requestType === 'create') {
    resp = await createAllInOne(fetcher, body);
  } else if (requestType === 'update') {
    resp = await updateAllInOne(fetcher, body, migrationId);
  }

  if (resp?.id) {
    router.push(
      `${currentLocaleForRouter}/migrations/all-in-one-migration/${resp?.id}/checkout/`
    );
  }
};

export const numberInputValidation = (event) => {
  if (
    event.key === 'Enter' ||
    event.key === 'e' ||
    event.key === 'E' ||
    event.key === '.' ||
    event.key === '+' ||
    event.key === '-'
  ) {
    event.preventDefault();
  }
};

export const gerenerateInitialAllInOnePrice = (
  migrationConfig,
  initialEntities = {}
) => {
  let valueObject = {};
  const entities = migrationConfig.entities;
  const additionals = migrationConfig.additionals;
  const customFee = migrationConfig.customization_fee;
  valueObject['customFee'] = customFee ? customFee : 0;
  entities.forEach((entity) => {
    if (entity.is_quantitative) {
      valueObject[entity.entity.alias] =
        initialEntities[entity.entity.alias] || entity.total;
    }
  });
  additionals.forEach((additional) => {
    if (additional.has_input) {
      valueObject[additional.alias] = additional.total ? additional.total : 0;
    } else if (!additional.has_input) {
      valueObject[additional.alias] = additional.enabled
        ? additional.enabled
        : false;
    }
  });
  return valueObject;
};

export const calculateAllInOneNewPrice = async (
  fetcher,
  initialValluesForPrice,
  setPrice
) => {
  const {
    products: productsCount,
    customers: customersCount,
    orders: ordersCount,
    [`multi-thread`]: multithreadingCount,
    [`multi-language`]: multilanguage,
    customFee,
  } = initialValluesForPrice;
  await getCalculatedPrice(
    fetcher,
    productsCount,
    customersCount,
    ordersCount
  ).then((res) => {
    let newPrice = Number(res?.pro.price);
    if (multithreadingCount) {
      newPrice = Number(res?.pro.price + (Number(res?.pro.price) * 20) / 100);
    }

    if (multilanguage > 0) {
      newPrice = newPrice + Number(multilanguage) * 100;
    }
    if (customFee > 0) {
      newPrice = newPrice + Number(customFee);
    }

    setPrice({
      ...res?.pro,
      price: newPrice,
    });
  });
};

export const triggerFormValidation = async (
  formikRoot,
  formik = null,
  uploadFilesError = false
) => {
  formikRoot.handleSubmit();
  let rootErrors = await formikRoot.validateForm();

  if (formik) {
    formik.handleSubmit();
    let formikErrors = await formik.validateForm();
    rootErrors = { ...rootErrors, ...formikErrors };
  }

  return Object.keys(rootErrors).length > 0 || uploadFilesError;
};

export function extractLinksAndTexts(errorMessage) {
  const linkRegex = /<a href='(.*?)'>(.*?)<\/a>/g;
  const links = [];
  let match;

  while ((match = linkRegex.exec(errorMessage)) !== null) {
    links.push({ href: match[1], text: match[2] });
  }

  links.forEach((link) => {
    errorMessage = errorMessage.replace(
      `<a href='${link.href}'>${link.text}</a>`,
      `<a href='${link.href}' target='_blank' style="color:#2b6cb0">${link.text}</a>`
    );
  });
  return errorMessage;
}

export const chatLinkReplace = (message, cartName, migrationId) => {
  if (message.includes('intercom_chat_link')) {
    const connectionProblemMessage = `<a onClick="Intercom('showNewMessage', 'Hi, I have a problem with my connection to ${cartName}. Migration ID: #${migrationId}')" style="color:#2b6cb0; cursor:pointer">Chat now</a>`;
    message = message.replace('intercom_chat_link', connectionProblemMessage);
  }

  return message;
};

export const checkConnectionSuccess = async (
  fetcher,
  migration,
  migrationId,
  setSourceError,
  setTargetError,
  alertConnectionErrorText
) => {
  setSourceError(null);
  setTargetError(null);
  const sourceCartName = migration.source_cart?.name;
  const targetCartName = migration.target_cart?.name;
  let error = false;
  /**
   * Check source and target cart connection status
   */
  const sourceConnection = await fetcher?.(
    `/migrations/${migrationId}/connector-check/?cart_type=source`,
    {},
    'GET'
  );
  const targetConnection = await fetcher?.(
    `/migrations/${migrationId}/connector-check/?cart_type=target`,
    {},
    'GET'
  );

  if (sourceConnection.status === 400) {
    error = true;
    let errorMessage = alertConnectionErrorText(
      `<b>Source ${sourceCartName}: </b> `
    );

    // if there is an error message from backend, show it
    if (sourceConnection?.data?.non_field_errors) {
      errorMessage =
        `<b>Source ${sourceCartName}: </b> ` +
        sourceConnection?.data?.non_field_errors;
    }
    errorMessage = errorMessage?.replace('intercom_chat_link', 'chat.');
    setSourceError(errorMessage);
  }

  if (targetConnection.status === 400) {
    error = true;
    let errorMessage = alertConnectionErrorText(
      `<b>Target ${targetCartName}: </b> `
    );
    // if there is an error message from backend, show it
    if (targetConnection?.data?.non_field_errors) {
      errorMessage =
        `<b>Target ${targetCartName}: </b> ` +
        targetConnection?.data?.non_field_errors;
    }
    errorMessage = errorMessage?.replace('intercom_chat_link', 'chat.');
    setTargetError(errorMessage);
  }
  if (error) return false;
  return true;
};
