/**
 * Migration Connection step API handlers
 */

export async function updateMigration(
  fetcher,
  migrationId,
  requestData,
  router = false
) {
  const response = await fetcher?.(
    `/migrations/${migrationId}/`,
    requestData,
    'PATCH'
  );
  let message = '';
  let data = null;
  let error = false;
  //if this migration doesn't exist, redirect to the existing migration
  const newMigrationId = response?.data?.id;
  if (response?.status == 301 && newMigrationId && router) {
    router?.replace(`/migrations/${newMigrationId}/connect`);
    error = true;
    return { message, error, data, redirect: true };
  }
  if (response?.id) {
    data = response.data;
  } else {
    error = true;

    if (response?.data?.target_cart_url) {
      message = response?.data?.target_cart_url;
    }

    if (response?.data?.non_field_errors) {
      message = response?.data?.non_field_errors;
    }

    if (response?.data?.auth_data) {
      message = response?.data?.auth_data;
    }
  }

  return { message, error, data, redirect: false };
}

/**
 * Apply Discount Coupon
 */
export async function applyCoupon(fetcher, type, migrationId, code) {
  const response = await fetcher?.(
    `/payment/pay/${type}/${migrationId}/`,
    { coupon: code },
    'PATCH'
  );
  return response;
}

export async function checkConnection(
  fetcher,
  migrationId,
  cartType,
  successfullyConnectedText
) {
  const response = await fetcher?.(
    `/migrations/${migrationId}/connector-check/?cart_type=${cartType}`,
    {},
    'GET'
  );

  const cartDestination = cartType === 'source' ? 'Source' : 'Target';

  let message = '';
  let data = null;
  let error = false;

  if (response.status === 200) {
    data = response.data;
    message = successfullyConnectedText(cartDestination);
  } else {
    if (response?.data?.target_cart_url) {
      message = response?.data?.target_cart_url;
    }

    if (response?.data?.non_field_errors) {
      message = response?.data?.non_field_errors;
    }

    if (response?.data?.auth_data) {
      message = response?.data?.auth_data;
    }
    // if (!message)
    //   message =
    //     "Connection not established. Don't hesitate to get in touch with us.";
    error = true;
  }

  return { message, error, data };
}

export async function getMigration(fetcher, migrationId) {
  const response = await fetcher?.(`/migrations/${migrationId}/`, {}, 'GET');

  let message = '';
  let data = null;
  let error = false;

  if (response.status === 404) {
    error = true;
  } else {
    data = response;
  }

  return { message, error, data };
}

export async function getAllInOneMigration(fetcher, migrationId) {
  const response = await fetcher?.(
    `/migrations/all-in-one/${migrationId}/`,
    {},
    'GET'
  );

  let message = '';
  let data = null;
  let error = false;

  if (response.status === 404) {
    error = true;
  } else {
    data = response;
  }

  return { message, error, data };
}

/**
 * Migration mapping API handlers
 */
export async function updateMapping(fetcher, migrationId, data) {
  const response = await fetcher?.(
    `/migrations/${migrationId}/mappings/`,
    data,
    'PATCH'
  );
  return response;
}

/**
 * Migration Smart Update entities
 */
export async function smartUpdateMapping(fetcher, migrationId, data) {
  const response = await fetcher?.(
    `/migrations/${migrationId}/subjects/`,
    data,
    'PATCH'
  );
  return response;
}

/**
 * Start Migration
 */
export async function startMigration(fetcher, migrationId, data) {
  const response = await fetcher?.(
    `/migrations/${migrationId}/controls/`,
    data,
    'POST'
  );
  return response;
}

/**
 * Balance API actions
 */
export async function increaseBalance(
  fetcher,
  payment_method = 'card',
  payment_source,
  payment_intent,
  amount
) {
  const response = await fetcher?.(
    '/payment/balance/top-up/',
    {
      payment_method,
      payment_source,
      payment_intent,
      amount,
    },
    'POST'
  );
  return response;
}

/**
 * Pay for migration
 */
export async function payForMigration(fetcher, migrationId, allInOne) {
  let response;
  if (allInOne) {
    response = await fetcher?.(
      `/payment/pay/allinone/${migrationId}/`,
      {},
      'POST'
    );
  } else {
    response = await fetcher?.(
      `/payment/pay/migration/${migrationId}/`,
      {},
      'POST'
    );
  }
  return response;
}

/**
 * Get Stripe Clint Secret
 */
export async function getStripeClientSecret(fetcher, amount) {
  const result = await fetcher?.(
    '/payment/balance/top-up/',
    {
      payment_method: 'card',
      payment_source: 'defaultCard',
      amount,
    },
    'POST'
  );
  const { client_secret } = result;
  return client_secret;
}

/**
 * Update user profile
 */
export async function updateUserProfile(fetcher, data) {
  const response = await fetcher?.('/accounts/me/', data, 'PUT');
  return response;
}

/**
 * Update user password
 */
export async function updateUserPassword(fetcher, data) {
  const response = await fetcher?.(
    '/accounts/me/change-password/',
    data,
    'PUT'
  );
  return response;
}

/**
 * Reset password
 */
export async function resetUserPassword(fetcher, data) {
  const response = await fetcher?.(
    '/accounts/recovery/reset-password/',
    data,
    'POST'
  );
  return response;
}

/**
 * Set password
 */
export async function setUserPassword(fetcher, data) {
  const response = await fetcher?.(
    '/accounts/recovery/set-password/',
    data,
    'POST'
  );
  return response;
}

/**
 * Shopify payment charge
 */
export async function shopifyCreateCharge(fetcher, data) {
  const response = await fetcher?.(`/payment/shopify/charge`, data, 'POST');
  return response;
}

/**
 * Request cart feature
 */
export async function requestCartFeature(fetcher, data) {
  const response = await fetcher?.(`/migrations/cart_request/`, data, 'POST');
  return response;
}

/**
 * Handle csv file
 */
export async function handleCsvFile(
  fetcher,
  migrationId,
  fileType,
  files,
  entity
) {
  var formData = new FormData();
  // if files passed, append it to formData

  if (files) {
    formData.append('csv_file', files[0]);
    formData.append('file_type', fileType);
    formData.append('entity_alias', entity);
  }

  // Send request based on whether files were passed or not
  const response = await fetcher?.(
    `/migrations/${migrationId}/csv/`,
    files ? formData : {},
    files ? 'POST' : 'GET'
  );

  return response;
}

/**
 * Install plugin
 */
export async function installPlugin(fetcher, migrationId, data) {
  const response = await fetcher?.(
    `/migrations/${migrationId}/plugin_install/`,
    data,
    'POST'
  );
  return response;
}

/**
 *
 * Get oAuth app install url
 *
 */
export async function getAppOauthLink(
  fetcher,
  appId,
  cartAlias,
  cartType,
  migrationId
) {
  const response = await fetcher?.(
    `/api_cart/oauth/${cartAlias}/install-app/?app_id=${appId}&cart_type=${cartType}&migration_id=${migrationId}`,
    {},
    'GET'
  );

  let message = '';
  let data = null;
  let error = false;

  if (response.status === 404) {
    error = true;
  } else {
    data = response;
  }

  return { message, error, data };
}

/**
 *
 * Get Store data
 *
 */
export async function getStoreData(fetcher, migrationId, cartType) {
  const response = await fetcher?.(
    `/migrations/${migrationId}/store_data/${cartType}`,
    {},
    'GET'
  );

  let message = '';
  let data = null;
  let error = false;

  if (response.status === 404) {
    error = true;
  } else {
    data = response;
  }

  return { message, error, data };
}

export async function handleLead(fetcher, data = {}, type = 'GET') {
  let isLead = true;
  const resp = await fetcher?.(`/marketing/lead/`, data, type);
  //if this user is not a lead , return false
  if (resp.status === 404) {
    isLead = false;
  }
  return isLead;
}

export async function handleProgress(fetcher, migrationId, type = 'GET') {
  const resp = await fetcher?.(`/migrations/${migrationId}/progresses/`, type);
  return resp;
}

/**
 *
 * Get Entities for All-in-one migrations
 *
 */

export async function getServices(
  fetcher,
  type = 'GET',
  source_cart,
  target_cart
) {
  const response = await fetcher?.(
    `/migrations/all-in-one/services/?source_cart=${source_cart}&target_cart=${target_cart}`,
    {},
    type
  );
  return response;
}

/**
 * Start Migration
 */
export async function createAllInOne(fetcher, data) {
  const response = await fetcher?.(`/migrations/all-in-one/`, data, 'POST');
  return response;
}

export async function updateAllInOne(fetcher, data, migrationId) {
  const response = await fetcher?.(
    `/migrations/all-in-one/${migrationId}/`,
    data,
    'PATCH'
  );
  return response;
}

export async function getCalculatedPrice(fetcher, product, customer, order) {
  const response = await fetcher?.(
    `/marketing/price-calc/?product_count=${product}&customer_count=${customer}&order_count=${order}`,
    'GET'
  );
  return response;
}
export async function calculateMigrationPrice(
  fetcher,
  entities = {},
  type = 'GET'
) {
  let queryString = '';
  for (let key in entities) {
    queryString += `&${key}_count=${entities[key].count}`;
  }
  const resp = await fetcher?.(`/marketing/price-calc/?${queryString}`, type);
  return resp;
}

export async function startRecentDataMigration(fetcher, migrationId) {
  const resp = await fetcher?.(
    `/migrations/${migrationId}/controls/?recent_data=true`,
    { action: 'start' },
    'POST'
  );
  return resp;
}
