import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import '@fontsource/inter/variable.css';
import axios from 'axios';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import NextNProgress from 'nextjs-progressbar';
import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { SWRConfig } from 'swr';
import { theme } from '../chakra-ui-pro-theme/index';
import { API_BASE_URL, teamMembersEmails } from '../components/utils/const';
import { checkTeamMemmber } from '../lib/common';
import { useCreateStore, zustandContext } from '../lib/store';
const myTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.blue },
  },
  theme
);

function App({ Component, pageProps }) {
  const createStore = useCreateStore(pageProps.initialZustandState);
  const store = useRef(createStore()).current;
  const session = pageProps.session;
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return;
    const tagManagerArgs = {
      gtmId: 'GTM-57QPLXV',
    };

    if (session) {
      if (
        !session.is_staff &&
        !checkTeamMemmber(teamMembersEmails, session.user?.email)
      ) {
        LogRocket.init('qotryh/migrationpro');
        setupLogRocketReact(LogRocket);
        LogRocket.identify(session.id, {
          name: session.user.name,
          email:
            session.profile.registered_from === 'migrationpro'
              ? session.user.email
              : session.dedicatedApp.original_email,
        });
      }

      tagManagerArgs.dataLayer = {
        // userId: session?.id,
        name: session?.user.name,
        email:
          session.profile.registered_from === 'migrationpro'
            ? session.user.email
            : session.dedicatedApp.original_email,
        hmac: session?.profile.intercom_unique_identifier,
        dateJoined: session?.profile.date_joined_unix_time,
        registeredFrom: session?.profile.registered_from,
      };
    }

    TagManager.initialize(tagManagerArgs);
  }, [session, pageProps]);

  const fetcher = (url, data, type, blob) => {
    /*
      Axios instance for fetcher. This is so cool :D
    */
    const instance = axios.create({ baseURL: API_BASE_URL, timeout: 6000000 });
    const config = { url: url };

    if (session) {
      config.headers = { Authorization: `Bearer ${session?.accessToken}` };
    }

    if (data) {
      config.data = data;
      config.method = type;
    }

    if (blob) {
      config.responseType = 'blob';
    }

    return instance
      .request(config)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          // client received an error response (5xx, 4xx)
          return error.response;
        } else if (error.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  };

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <SessionProvider
      session={pageProps.session}
      refetchInterval={5 * 60}
      refetchOnWindowFocus={true}
    >
      <ChakraProvider theme={myTheme}>
        <SWRConfig
          value={{
            // revalidateIfStale: false,
            // revalidateOnFocus: false,
            // revalidateOnReconnect: false,
            fetcher,
          }}
        >
          <zustandContext.Provider value={store}>
            <NextNProgress />
            {getLayout(<Component {...pageProps} />)}
          </zustandContext.Provider>
        </SWRConfig>
      </ChakraProvider>
    </SessionProvider>
  );
}

export default appWithTranslation(App);
