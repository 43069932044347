import { createContext, useLayoutEffect } from 'react';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
let store;

const getDefaultInitialState = () => ({
  sourceUrl: '',
  targetUrl: '',
  sourceCart: null,
  targetCart: null,
  authDataSource: {},
  authDataTarget: {},
  sourceCartConnected: false,
  targetCartConnected: false,
  sourceCartConnectionMethod: 'bridge',
  targetCartConnectionMethod: 'credential',
  migrationId: null,
  connectionNew: true,
});

export const zustandContext = createContext(null);

export const initializeStore = (preloadedState = {}) => {
  return create(
    devtools(
      persist(
        (set) => ({
          ...getDefaultInitialState(),
          ...preloadedState,
          setConnectionNew: (data) => {
            set({
              connectionNew: data,
            });
          },
          setSourceCartConnected: (data) => {
            set({
              sourceCartConnected: data,
            });
          },
          setTargetCartConnected: (data) => {
            set({
              targetCartConnected: data,
            });
          },
          setSourceCartConnectionMethod: (data) => {
            set({
              sourceCartConnectionMethod: data,
            });
          },
          setTargetCartConnectionMethod: (data) => {
            set(() => ({
              targetCartConnectionMethod: data,
            }));
          },
          setAuthDataSource: (data) => {
            set({
              authDataSource: data,
            });
          },
          setAuthDataTarget: (data) => {
            set({
              authDataTarget: data,
            });
          },
          setMigrationId: (id) => {
            set({
              migrationId: id,
            });
          },
          setSourceCart: (cart) => {
            if (cart === null) {
              set({
                sourceCart: null,
              });
            } else {
              const cartObj = cart[Object.keys(cart)[0]];
              set({
                sourceCart: cartObj,
              });
            }
          },
          setTargetCart: (cart) => {
            if (cart === null) {
              set({
                targetCart: null,
              });
            } else {
              const cartObj = cart[Object.keys(cart)[0]];
              set({
                targetCart: cartObj,
              });
            }
          },
          setSourceUrl: (url) => {
            set({
              sourceUrl: url,
            });
          },
          setTargetUrl: (url) => {
            set({
              targetUrl: url,
            });
          },
          reset: () => {
            set(getDefaultInitialState());
          },
        }),
        {
          name: 'mpio',
          storage: createJSONStorage(() => sessionStorage),
          equalityFn: shallow,
        }
      )
    )
  );
};

export function useCreateStore(serverInitialState) {
  // Server side code: For SSR & SSG, always use a new store.
  if (typeof window === 'undefined') {
    return () => initializeStore(serverInitialState);
  }
  // End of server side code

  // Client side code:
  // Next.js always re-uses same store regardless of whether page is a SSR or SSG or CSR type.
  const isReusingStore = Boolean(store);
  store = store ?? initializeStore(serverInitialState);
  // When next.js re-renders _app while re-using an older store, then replace current state with
  // the new state (in the next render cycle).
  // (Why next render cycle? Because react cannot re-render while a render is already in progress.
  // i.e. we cannot do a setState() as that will initiate a re-render)
  //
  // eslint complaining "React Hooks must be called in the exact same order in every component render"
  // is ignorable as this code runs in same order in a given environment (i.e. client or server)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    // serverInitialState is undefined for CSR pages. It is up to you if you want to reset
    // states on CSR page navigation or not. I have chosen not to, but if you choose to,
    // then add `serverInitialState = getDefaultInitialState()` here.
    if (serverInitialState && isReusingStore) {
      store.setState(
        {
          // re-use functions from existing store
          ...store.getState(),
          // but reset all other properties.
          ...serverInitialState,
        },
        true // replace states, rather than shallow merging
      );
    }
  });

  return () => store;
}
